<template>
  <div id="unggas-nasional-data-distribusi-add-send-doc" data-app>

    <!--begin::Form-->
    <ValidationObserver ref="formAdd">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Tipe Ternak</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Tipe Ternak" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-perusahaan-3" 
                        label-for="input-perusahaan-3"
                        :invalid-feedback="errors[0]"
                        description="Klasifikasi ternak yang akan didistribusikan"
                        class="mb-0"
                        :state="valid">
                        <b-form-select2
                          id="input-perusahaan-3"
                          v-model="form.tipe"
                          :options="opsTipe"
                          required
                          variant="solid"
                          size="lg"
                          placeholder="Silahkan pilih Tipe Ternak"
                          :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Perusahaan</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Perusahaan" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-perusahaan-1" 
                        label-for="input-perusahaan-1"
                        :invalid-feedback="errors[0]"
                        description="Tujuan Dostribusi DOC"
                        class="mb-0"
                        :state="valid">
                          <b-form-select2
                          id="input-perusahaan-1"
                          v-model="form.perusahaan"
                          required
                          variant="solid"
                          size="lg"
                          :options="opsPerusahaan"
                          placeholder="Silahkan pilih Provinsi Farm"
                          :state="valid"
                          ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>

        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5 mt-15"></div>
        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 class="font-weight-bold mb-6">Lokasi Pengiriman:</h5>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Nama Farm/Kandang</label>
            <div class="col-lg-9 col-xl-7">
                <span @click="openDialogFarm" class="form-control form-control-solid form-control-lg text-left">{{ nameFarm }}</span>
                <span class="form-text text-muted">Untuk memilih farm form Perusahaan tidak boleh kosong</span>
            </div>
        </div>

        <div v-if="form.farm">   
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.provinsi }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.kabupaten }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Kecamatan</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.kecamatan }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Desa</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.desa }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.alamat }}</span>
              </div>
          </div>
        </div>

        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5 mt-15"></div>
        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 class="font-weight-bold mb-6">Rincian:</h5>
            </div>
        </div>       
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Tgl. Distribusi</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Tanggal Distribusi" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-perusahaan-4" 
                        label-for="input-perusahaan-4"
                        :invalid-feedback="errors[0]"
                        description="pilih tanggal sesuai yang ada di surat jalan."
                        class="mb-0"
                        :state="valid">
                        <b-form-datepicker
                          id="input-perusahaan-4"
                          class="form-control form-control-solid pr-0"
                          v-model="form.tgl_distribusi"
                          size="lg"
                          locale="id"
                          required
                          placeholder="Tanggal Distribusi"
                          :state="valid"
                        ></b-form-datepicker>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Int/Eks</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Int/Eks" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-perusahaan-2" 
                        label-for="input-perusahaan-2"
                        :invalid-feedback="errors[0]"
                        class="mb-0"
                        :state="valid">
                        <b-form-select2
                          id="input-perusahaan-2"
                          v-model="form.kategori"
                          :options="opsKategoriDistribusi"
                          required
                          variant="solid"
                          size="lg"
                          placeholder="Silahkan pilih Int/Eks"
                          :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>  
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Strain</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Strain" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-perusahaan-5" 
                        label-for="input-perusahaan-5"
                        :invalid-feedback="errors[0]"
                        class="mb-0"
                        :state="valid">
                        <b-form-select2
                          id="input-perusahaan-5"
                          v-model="form.strain"
                          :options="opsStrain"
                          required
                          variant="solid"
                          size="lg"
                          placeholder="Silahkan pilih Strain"
                          :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>  
        <b-row v-for="(item, index) in form.rincian" :key="index">
            <b-col cols="10" class="pt-5 pb-0">
                <h6 class="text-muted">Sex {{index + 1}}</h6>
            </b-col>
            <b-col cols="2" class="text-right pt-5 pb-0">
                <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                </b-button>
            </b-col>       
            <b-col cols="12" md="6" class="pt-0 pb-0">
                <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ valid, errors }">
                <b-form-group 
                :id="`input-group-${index}-3`" 
                label="Sex:" 
                :label-for="`input-${index}-3`"
                :invalid-feedback="errors[0]"
                :state="valid">
                    <b-form-select2
                      :id="`input-${index}-3`"
                      variant="solid"
                      size="lg"
                      :options="opsSex"
                      v-model="item.sex"
                      required
                      :state="valid"
                    >
                    </b-form-select2>
                </b-form-group>
                </validationProvider>
            </b-col>
            <b-col cols="12" md="6" class="pt-0 pb-0">
                <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-${index}-2`" 
                  label="Jumlah:" 
                  :label-for="`input-${index}-2`"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                      <vue-number-input 
                        :attrs="{ id: `input-${index}-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="item.jumlah"
                        :min="0"
                        :max="9999999999"
                        size="large"
                        required
                        center
                        controls
                      ></vue-number-input>
                  </b-form-group>
                </validationProvider>
            </b-col>
        </b-row>

        <b-row class="mb-5">
            <b-col cols="12" class="text-right">
                <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                <i class="flaticon2-plus-1 p-0"></i>
                Add Sex
                </b-button>
            </b-col>
        </b-row>
        
        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5 mt-15"></div>
        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 class="font-weight-bold mb-6">Asal DOC:</h5>
            </div>
        </div>       
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Pullchick</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider :name="`Asal DOC`" rules="required" v-slot="{ errors, valid }">
                  <b-form-group 
                  :id="`input-group-2`" 
                  :label-for="`input-2`"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                    <treeselect
                      :multiple="true"
                      required
                      :options="opsAsalDOC"
                      :normalizer="normalizer"
                      :flatten-search-results="true"
                      placeholder="Pilih Asal DOC (Pullchick)"
                      v-model="form.asal"
                    />
                  </b-form-group>
                </validationProvider>
            </div>
        </div>

        <button 
          @click="onSubmit" 
          ref="kt_submit"
          class="btn btn-primary font-weight-bold"
        >
          <i class="la la-save"></i>
          Simpan
        </button>
        &nbsp;
        <button
          @click="onReset"
          class="btn btn-clean font-weight-bold"
        >
          <i class="la la-recycle"></i>
          Reset
        </button>
    </b-form>
    </ValidationObserver>

    <v-dialog v-model="dialogFarm" persistent scrollable max-width="500px">
      <div class="card card-custom">
				<!--begin::Header-->
				<div class="card-header border-0 mt-5">
					<div class="d-flex flex-center mt-3 mb-4 px-6 bg-light rounded">
            <span class="svg-icon svg-icon-lg svg-icon-info">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/General/Search.svg" />
                <!--end::Svg Icon-->
            </span>
						<input type="text" v-model="searchFarm" class="form-control border-0 font-weight-bold pl-2 bg-light" placeholder="Search Farm">
					</div>
					<div class="card-toolbar">
						
					</div>
				</div>
				<!--end::Header-->
				<!--begin::Body-->
				<div class="card-body pt-2 h-100">
          <perfect-scrollbar
          class="scroll"
          style="max-height: 92%; position: relative;"
          >
            <div class="mt-5" v-if="!isBusy">
              <!--begin::Item-->
              <div v-for="(item, index) in filterFarm" :key="index" @click="selectFarm(item)" class="d-flex align-items-center cursor-pointer bg-hover-light rounded p-2 mb-8">
                <!--begin::Symbol-->
                <div class="symbol symbol-40 symbol-light-primary mr-5">
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
                          <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1" />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Section-->
                <div class="d-flex flex-column flex-grow-1">
                  <!--begin::Title-->
                  <a href="#" class="text-dark font-weight-bolder font-size-lg text-hover-primary mb-1">Farm {{ item.text }}</a>
                  <!--end::Title-->
                  <!--begin::Desc-->
                  <span class="text-dark-50 font-weight-normal font-size-sm">
                    Provinsi {{ item.provinsi.uraian }}, 
                    {{ item.kabupaten.uraian }}, 
                    Kecamatan {{ item.kecamatan.uraian }}, 
                    Desa {{ item.desa.uraian }}, 
                    {{ item.alamat }}
                  </span>
                  <!--begin::Desc-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
            </div>
            <div class="mt-5" v-else>
              <div class="mb-3"><ContentLoader height="20"></ContentLoader></div>
              <div class="mb-3"><ContentLoader height="20"></ContentLoader></div>
            </div>
          </perfect-scrollbar>
				</div>
				<!--end::Body-->
        <div class="card-footer py-3">
            <button
            class="btn btn-clean font-weight-bold m-3"
            type="button"
            @click="closeDialogFarm()">
                <i class="la la-recycle"></i>
                Close
            </button>
        </div>
			</div>
    </v-dialog>

  </div>

</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ContentLoader } from 'vue-content-loader'
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import DistribusiService from "@/core/services/api/nasional/distribusi.service";
import ErrorService from "@/core/services/error.service";

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "unggas-nasional-data-distribusi-add-send-doc",
  props: {
    sdata: {
      required: true
    }
  },
  components: {
    ContentLoader,
    Treeselect
  },
  data() {
    return {
      loading: true,
      isBusy: true,
      dialogFarm: false,
      searchFarm: '',
      farm: {
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
      },
      form: {
        _id: this.sdata._id || null,
        menu: 'perusahaan',
        perusahaan: null,
        farm: null,
        tgl_distribusi: null,
        kategori: null,
        tipe: null,
        asal:[],
        rincian: [{
          sex: null,
          jumlah: 0
        }]
      },
      opsPerusahaan: [{ 
        text: 'Tidak ada Perusahaan', 
        value: null 
      }],
      opsSex: [{ 
        text: 'Tidak ada Sex', 
        value: null 
      }],
      opsKategoriDistribusi: [{ 
        text: 'Tidak ada Int/Eks', 
        value: null 
      }],
      opsTipe: [{ 
        text: 'Tidak ada Tipe Ternak', 
        value: null 
      }],
      opsStrain: [{ 
        text: 'Tidak ada Strain', 
        value: null 
      }],
      opsAsalDOC: [],
      opsFarm: [],
      show: true
    }
  },
  computed: {
    filterFarm() {
      const result = this.opsFarm.filter((row, index) => {
          return row.text.toLowerCase().includes(this.searchFarm.toLowerCase())
      })
      return result
    },
    nameFarm() {
      if (this.form.farm) {
        return this.farm.nama
      }

      return 'Silahkn Pilih Farm'
    },
    cekSex () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.sex] = ++a[e.sex] || 0;
        return a;
      }, {});

      return this.opsSex.filter(e => lookup[e.value] && e.value !== null)
    },
  },
  watch: {
    'form.perusahaan' (val, old) {
      if (val != old) {
        this.form.farm = null
        this.farm = {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          desa: null,
          alamat: null,
        }
      }
    },
    'form.tipe'(val, old) {
      if (val != old) {
        this.form.perusahaan = null
        for (let index = 0; index < this.form.rincian.length; index++) {
          this.form.rincian[index].sex = null          
        }

        this.getPerusahaan(val)
        this.getTernakSex(val)
      }
    },
    'form.tgl_distribusi' (val, old) {
      if (val != old) {
        if(!this.loading) {
          this.form.asal = []
        }
        this.getProduksiDOC(val)
      }
    }
  },
  methods: {
    addRincian(){
      this.form.rincian.push({
        sex: null,
        jumlah: 0
      })
    },
    deleteRincian(index){
      if (this.form.rincian.length > 1) {
        this.form.rincian.splice(index,1);
      }
      else {
        this.form.rincian = [{
          sex: null,
          jumlah: 0
        }]
      }
    }, 
    openDialogFarm() {
      if (!this.form.perusahaan) return
      this.getFarm();
      this.dialogFarm = true
    }, 
    closeDialogFarm() {
      this.opsFarm= []
      this.dialogFarm = false
    },
    selectFarm(item) {
      this.form.farm = item.value
      this.farm.nama = item.text
      this.farm.provinsi = item.provinsi.uraian
      this.farm.kabupaten = item.kabupaten.uraian
      this.farm.kecamatan = item.kecamatan.uraian
      this.farm.desa = item.desa.uraian
      this.farm.alamat = item.alamat

      this.closeDialogFarm()
    },
    normalizer(node) {
      if (!node) return
      return {
        id: node.value,
        label: `${node.text} ${this.$options.filters.dateIndonesia(node.tanggal)}`,
        children: node.childrens,
      }
    },
    getTernakTipe() {
      MasterService.getTernakTipe()
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsTipe = [{
                  value: null,
                  text: "Tidak ada Tipe Ternak"
              }]
              return;
          }

          this.opsTipe = res.data.data
          this.opsTipe.push({
              value: null,
              text: "Silahkan pilih Tipe Ternak"
          })
      })
      .catch((err) => {
        ErrorService.status(err)
        this.opsTipe = [{
            value: null,
            text: "Tidak ada Tipe Ternak"
        }]
      })
    },
    getTernakStrain() {
      MasterService.getTernakStrain()
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsStrain = [{
                  value: null,
                  text: "Tidak ada Strain"
              }]
              return;
          }

          this.opsStrain = res.data.data
          this.opsStrain.push({
              value: null,
              text: "Silahkan pilih Strain"
          })
      })
      .catch((err) => {
        ErrorService.status(err)
        this.opsStrain = [{
            value: null,
            text: "Tidak ada Strain"
        }]
      })
    },
    getPerusahaan(val) {
      if (!val) {
        return
      }
      PerusahaanService.getPerusahaan({ child: 1, tipe: val })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsPerusahaan = [{
                  value: null,
                  text: "Tidak ada Perusahaan"
                }]
                return;
            }

            this.opsPerusahaan = res.data.data
            this.opsPerusahaan.push({
              value: null,
              text: "Silahkan pilih Perusahaan"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsPerusahaan = [{
            value: null,
            text: "Tidak ada Perusahaan"
          }]
        })
    },    
    getFarm() {
      this.isBusy = true
      PerusahaanService.getPerusahaanFarm({ child: 1, perusahaan: this.form.perusahaan })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsFarm = []
                this.isBusy = false
                return;
            }

            this.opsFarm = res.data.data
            this.isBusy = false
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsFarm = []
          this.isBusy = false
        })
    },
    getTernakSex(val) {
      if (!val) {
        return
      }
      MasterService.getTernakSex({tipe: val})
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsSex = [{
                  value: null,
                  text: "Tidak ada Sex"
              }]
              return;
          }

          this.opsSex = res.data.data
          this.opsSex.push({
              value: null,
              text: "Silahkan pilih Sex"
          })
      })
      .catch((err) => {
        ErrorService.status(err)
        this.opsSex = [{
            value: null,
            text: "Tidak ada Sex"
        }]
      })
    },
    getKategoriDistribusi() {
      MasterService.getKategoriDistribusi()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKategoriDistribusi = [{
                  value: null,
                  text: "Tidak ada Int/Eks"
                }]
                return;
            }

            this.opsKategoriDistribusi = res.data.data
            this.opsKategoriDistribusi.push({
              value: null,
              text: "Silahkan pilih Int/Eks"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsKategoriDistribusi = [{
            value: null,
            text: "Tidak ada Int/Eks"
          }]
        })
    },
    getProduksiDOC(tanggal) {
      if (!tanggal) {
        return
      }
      DistribusiService.getProduksiDOC({tanggal})
      .then((res) => {
        if (!res.data.status) {
            ErrorService.message(res.data)
            this.opsAsalDOC = []
            this.loading = false
            return;
        }
        this.opsAsalDOC = res.data.data
        this.loading = false
      })
      .catch((err) => {
        this.opsAsalDOC = []
        this.loading = false
        return ErrorService.status(err)
      })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if(!this.form.farm) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Rincian data tidak lengkap',
            })
            return;
        }

        if (this.cekSex.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Double input sex',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            DistribusiService.addSendDoc(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.$router.push({ name: 'unggas-nasional-distribusi-tabel-list', param: {idH: this.sdata._id} })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form.perusahaan = null
      this.form.farm = null
      this.form.kategori = null
      this.form.strain = null
      this.form.asal = []
      this.form.rincian = [{
        sex: null,
        jumlah: 0
      }]

      this.farm = {
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
      }

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
        setTimeout(() => {       
        }, 500);
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Peternak" }]);
    this.getKategoriDistribusi()
    this.getTernakTipe()
    this.getTernakStrain()
  },
};
</script>