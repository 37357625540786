<template>
  <div id="unggas-nasional-data-distribusi-add-send-doc" data-app>

    <!--begin::Form-->
    <ValidationObserver ref="formIdentitas">
    <b-form @submit="onSubmitIdentitas" @reset="onReset" v-if="show">
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Tipe Kode</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Tipe Kode" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-peternak-3" 
                        label-for="input-peternak-3"
                        :invalid-feedback="errors[0]"
                        description="Sesuaikan dengan kode yang akan diinput"
                        class="mb-0"
                        :state="valid">
                        <b-form-select2
                          id="input-peternak-3"
                          v-model="formIdentitas.tipe_kode"
                          :options="opsTipeKode"
                          required
                          variant="solid"
                          size="lg"
                          placeholder="Silahkan pilih Tipe Kode"
                          :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Kode</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider :name="`No. ${idnts.short}`" :rules="`required|digits:${idnts.digit}`" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-peternak-6" 
                        label-for="input-peternak-6"
                        :invalid-feedback="errors[0]"
                        class="mb-0"
                        :state="valid">
                        <b-form-input
                        autocomplete="off"
                          id="input-peternak-6"
                          v-model="formIdentitas.kode"
                          class="form-control form-control-solid"
                          required
                          size="lg"
                          :placeholder="`Nomor ${idnts.short}`"
                          :state="valid"
                          ></b-form-input>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>
        <button
        class="btn btn-primary font-weight-bold m-3"
        type="button"
        ref="kt_submit_identitas"
        @click="onSubmitIdentitas">
            <i class="la la-search"></i>
            Search
        </button>
    </b-form>
    </ValidationObserver>

    <ValidationObserver ref="formAdd">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show && identitas">
        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5 mt-15"></div>
        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 v-if="identitas.tipe == 'NIB'" class="font-weight-bold mb-6">Biodata:</h5>
                <h5 v-else class="font-weight-bold mb-6">Data Peternak:</h5>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Nama</label>
            <div class="col-lg-9 col-xl-7">
                <span class="form-control form-control-solid form-control-lg text-left">{{ identitas.nama }}</span>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Kode</label>
            <div class="col-lg-9 col-xl-7">
                <span class="form-control form-control-solid form-control-lg text-left">{{ identitas.kode }}</span>
            </div>
        </div>

        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5 mt-15"></div>
        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 class="font-weight-bold mb-6">Lokasi Pengiriman:</h5>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Nama Farm/Kandang</label>
            <div class="col-lg-9 col-xl-7">
                <span @click="openDialogFarm" class="form-control form-control-solid form-control-lg text-left">{{ nameFarm }}</span>
                <span class="form-text text-muted">Untuk memilih farm form Peternak tidak boleh kosong</span>
            </div>
        </div>

        <div v-if="form.farm">   
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.provinsi }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.kabupaten }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Kecamatan</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.kecamatan }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Desa</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.desa }}</span>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
              <div class="col-lg-9 col-xl-7">
                  <span class="form-control form-control-solid form-control-lg text-left">{{ farm.alamat }}</span>
              </div>
          </div>
        </div>

        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5 mt-15"></div>
        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 class="font-weight-bold mb-6">Rincian:</h5>
            </div>
        </div>       
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Tgl. Distribusi</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Tanggal Distribusi" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-peternak-4" 
                        label-for="input-peternak-4"
                        :invalid-feedback="errors[0]"
                        description="pilih tanggal sesuai yang ada di surat jalan."
                        class="mb-0"
                        :state="valid">
                        <b-form-datepicker
                          id="input-peternak-4"
                          class="form-control form-control-solid pr-0"
                          v-model="form.tgl_distribusi"
                          size="lg"
                          locale="id"
                          required
                          placeholder="Tanggal Distribusi"
                          :state="valid"
                        ></b-form-datepicker>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Int/Eks</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Int/Eks" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-peternak-2" 
                        label-for="input-peternak-2"
                        :invalid-feedback="errors[0]"
                        class="mb-0"
                        :state="valid">
                        <b-form-select2
                          id="input-peternak-2"
                          v-model="form.kategori"
                          :options="opsKategoriDistribusi"
                          required
                          variant="solid"
                          size="lg"
                          placeholder="Silahkan pilih Int/Eks"
                          :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>  
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Strain</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Strain" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-peternak-5" 
                        label-for="input-peternak-5"
                        :invalid-feedback="errors[0]"
                        class="mb-0"
                        :state="valid">
                        <b-form-select2
                          id="input-peternak-5"
                          v-model="form.strain"
                          :options="opsStrain"
                          required
                          variant="solid"
                          size="lg"
                          placeholder="Silahkan pilih Strain"
                          :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>  
        <b-row v-for="(item, index) in form.rincian" :key="index">
            <b-col cols="10" class="pt-5 pb-0">
                <h6 class="text-muted">Sex {{index + 1}}</h6>
            </b-col>
            <b-col cols="2" class="text-right pt-5 pb-0">
                <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                </b-button>
            </b-col>       
            <b-col cols="12" md="6" class="pt-0 pb-0">
                <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ valid, errors }">
                <b-form-group 
                :id="`input-group-${index}-3`" 
                label="Sex:" 
                :label-for="`input-${index}-3`"
                :invalid-feedback="errors[0]"
                :state="valid">
                    <b-form-select2
                      :id="`input-${index}-3`"
                      variant="solid"
                      size="lg"
                      :options="opsSex"
                      v-model="item.sex"
                      required
                      :state="valid"
                    >
                    </b-form-select2>
                </b-form-group>
                </validationProvider>
            </b-col>
            <b-col cols="12" md="6" class="pt-0 pb-0">
                <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-${index}-2`" 
                  label="Jumlah:" 
                  :label-for="`input-${index}-2`"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                      <vue-number-input 
                        :attrs="{ id: `input-${index}-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="item.jumlah"
                        :min="0"
                        :max="9999999999"
                        size="large"
                        required
                        center
                        controls
                      ></vue-number-input>
                  </b-form-group>
                </validationProvider>
            </b-col>
        </b-row>

        <b-row class="mb-5">
            <b-col cols="12" class="text-right">
                <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                <i class="flaticon2-plus-1 p-0"></i>
                Add Sex
                </b-button>
            </b-col>
        </b-row>
        
        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5 mt-15"></div>
        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 class="font-weight-bold mb-6">Asal DOC:</h5>
            </div>
        </div>       
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Pullchick</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider :name="`Asal DOC`" rules="required" v-slot="{ errors, valid }">
                  <b-form-group 
                  :id="`input-group-2`" 
                  :label-for="`input-2`"
                  :invalid-feedback="errors[0]"
                  :state="valid">
                    <treeselect
                      :multiple="true"
                      required
                      :options="opsAsalDOC"
                      :normalizer="normalizer"
                      :flatten-search-results="true"
                      placeholder="Pilih Asal DOC (Pullchick)"
                      v-model="form.asal"
                    />
                  </b-form-group>
                </validationProvider>
            </div>
        </div>

        <button 
          @click="onSubmit" 
          ref="kt_submit"
          class="btn btn-primary font-weight-bold"
        >
          <i class="la la-save"></i>
          Simpan
        </button>
        &nbsp;
        <button
          @click="onReset"
          class="btn btn-clean font-weight-bold"
        >
          <i class="la la-recycle"></i>
          Reset
        </button>
    </b-form>
    </ValidationObserver>

    <v-dialog v-model="dialogFarm" persistent scrollable max-width="500px">
      <div class="card card-custom">
				<!--begin::Header-->
				<div class="card-header border-0 mt-5">
					<div class="d-flex flex-center mt-3 mb-4 px-6 bg-light rounded">
            <span class="svg-icon svg-icon-lg svg-icon-info">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/General/Search.svg" />
                <!--end::Svg Icon-->
            </span>
						<input type="text" v-model="searchFarm" class="form-control border-0 font-weight-bold pl-2 bg-light" placeholder="Search Farm">
					</div>
					<div class="card-toolbar">
						
					</div>
				</div>
				<!--end::Header-->
				<!--begin::Body-->
				<div class="card-body pt-2 h-100">
          <perfect-scrollbar
          class="scroll"
          style="max-height: 92%; position: relative;"
          >
            <div class="mt-5" v-if="!isBusy">
              <!--begin::Item-->
              <div v-for="(item, index) in filterFarm" :key="index" @click="selectFarm(item)" class="d-flex align-items-center cursor-pointer bg-hover-light rounded p-2 mb-8">
                <!--begin::Symbol-->
                <div class="symbol symbol-40 symbol-light-primary mr-5">
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
                          <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1" />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Section-->
                <div class="d-flex flex-column flex-grow-1">
                  <!--begin::Title-->
                  <a href="#" class="text-dark font-weight-bolder font-size-lg text-hover-primary mb-1">Farm {{ item.text }}</a>
                  <!--end::Title-->
                  <!--begin::Desc-->
                  <span class="text-dark-50 font-weight-normal font-size-sm">
                    Provinsi {{ item.provinsi.uraian }}, 
                    {{ item.kabupaten.uraian }}, 
                    Kecamatan {{ item.kecamatan.uraian }}, 
                    Desa {{ item.desa.uraian }}, 
                    {{ item.alamat }}
                  </span>
                  <!--begin::Desc-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Item-->
            </div>
            <div class="mt-5" v-else>
              <div class="mb-3"><ContentLoader height="20"></ContentLoader></div>
              <div class="mb-3"><ContentLoader height="20"></ContentLoader></div>
            </div>
          </perfect-scrollbar>
				</div>
				<!--end::Body-->
        <div class="card-footer py-3">
            <button
            class="btn btn-primary font-weight-bold m-3"
            type="button"
            @click="openDialogFormFarm()">
                <i class="la la-plus"></i>
                Add
            </button>
            <button
            class="btn btn-clean font-weight-bold m-3"
            type="button"
            @click="closeDialogFarm()">
                <i class="la la-recycle"></i>
                Close
            </button>
        </div>
			</div>
    </v-dialog>

    <v-dialog
    v-model="modalAdd"
    max-width="300">
        <v-card>
            <v-card-title class="headline">Tambah data</v-card-title>

            <v-card-text>
                Identitas tidak ditemukan, Apakah anda ingin menambahkan <b>IDENTITAS</b> baru ?
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <b-button
            variant="link-success"
            class="m-3"
            @click="modalAdd = false">
                BATAL
            </b-button>

            <b-button
            variant="link-danger"
            class="m-3"
            @click="openDialogForm">
                LANJUTKAN
            </b-button>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogComponent" persistent scrollable max-width="1000px">
      <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

  </div>

</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ContentLoader } from 'vue-content-loader'
import PeternakService from "@/core/services/api/master/peternak.service";
import MasterService from "@/core/services/api/master/master.service";
import DistribusiService from "@/core/services/api/nasional/distribusi.service";
import ErrorService from "@/core/services/error.service";

import FormAddPeternak from "@/view/pages/nasional/distribusi/form/peternak/Add.vue";
import FormAddFarmPeternak from "@/view/pages/nasional/distribusi/form/peternak/AddFarm.vue";

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "unggas-nasional-data-distribusi-add-send-doc",
  props: {
    sdata: {
      required: true
    }
  },
  components: {
    ContentLoader,
    Treeselect,
    FormAddPeternak,
    FormAddFarmPeternak
  },
  data() {
    return {
      loading: true,
      isBusy: true,
      dialogFarm: false,
      modalAdd: false,
      dialogComponent: false,
      curComponent: null,
      sdataForm: null,
      searchFarm: '',
      formIdentitas: {
        tipe_kode: 'nip',
        kode: null,
      },
      identitas: null,
      farm: {
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
      },
      form: {
        _id: this.sdata._id || null,
        menu: 'peternak',
        peternak: null,
        kode: null,
        farm: null,
        tgl_distribusi: null,
        kategori: null,
        tipe: 6,
        strain: null,
        asal:[],
        rincian: [{
          sex: null,
          jumlah: 0
        }]
      },
      opsTipeKode: [{ 
        text: 'NIP (Nomor Induk Peternakan)', 
        value: 'nip',
        digit: 20,
        short: 'NIP'
      }, { 
        text: 'NIB (Nomor Induk Berusaha)', 
        value: 'nib',
        digit: 13,
        short: 'NIB'
      }, { 
        text: 'NIK (Nomor Induk Kependudukan)', 
        value: 'nik',
        digit: 16,
        short: 'NIK'
      }],
      opsSex: [{ 
        text: 'Tidak ada Sex', 
        value: null 
      }],
      opsKategoriDistribusi: [{ 
        text: 'Tidak ada Int/Eks', 
        value: null 
      }],
      opsStrain: [{ 
        text: 'Tidak ada Strain', 
        value: null 
      }],
      opsAsalDOC: [],
      opsFarm: [],
      show: true
    }
  },
  computed: {
    filterFarm() {
      const result = this.opsFarm.filter((row, index) => {
          return row.text.toLowerCase().includes(this.searchFarm.toLowerCase()) || 
              row.alamat.toLowerCase().includes(this.searchFarm.toLowerCase()) ||
              row.provinsi.uraian.toLowerCase().includes(this.searchFarm.toLowerCase()) ||
              row.kabupaten.uraian.toLowerCase().includes(this.searchFarm.toLowerCase()) ||
              row.kecamatan.uraian.toLowerCase().includes(this.searchFarm.toLowerCase()) ||
              row.desa.uraian.toLowerCase().includes(this.searchFarm.toLowerCase()) 
      })
      return result
    },
    nameFarm() {
      if (this.form.farm) {
        return this.farm.nama
      }

      return 'Silahkn Pilih Farm'
    },
    cekSex () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.sex] = ++a[e.sex] || 0;
        return a;
      }, {});

      return this.opsSex.filter(e => lookup[e.value] && e.value !== null)
    },
    idnts() {
      if(this.formIdentitas.tipe_kode) {
        const getData = this.opsTipeKode.find(ky => ky.value == this.formIdentitas.tipe_kode)
        return getData
      }
      return {
        text: null, 
        value: null,
        digit: 0,
        short: null
      }
    }
  },
  watch: {    
    'identitas.tipe_kode' (val, old) {
      if (val != old) {
        this.identitas.kode = null
      }
    },
    'form.peternak' (val, old) {
      if (val != old) {
        this.form.farm = null
        this.farm = {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          desa: null,
          alamat: null,
        }
      }
    },
    'form.tgl_distribusi' (val, old) {
      if (val != old) {
        if(!this.loading) {
          this.form.asal = []
        }
        this.getProduksiDOC(val)
      }
    }
  },
  methods: {
    addRincian(){
      this.form.rincian.push({
        sex: null,
        jumlah: 0
      })
    },
    deleteRincian(index){
      if (this.form.rincian.length > 1) {
        this.form.rincian.splice(index,1);
      }
      else {
        this.form.rincian = [{
          sex: null,
          jumlah: 0
        }]
      }
    }, 
    openDialogFarm() {
      if (!this.form.peternak) return
      this.getFarm();
      this.dialogFarm = true
    }, 
    closeDialogFarm() {
      this.opsFarm= []
      this.dialogFarm = false
    },
    openDialogForm() {
      if (!this.formIdentitas) return
      this.dialogComponent = true
      this.curComponent = 'FormAddPeternak'
      this.sdataForm = this.formIdentitas
      this.modalAdd = false
    }, 
    openDialogFormFarm() {
      if (!this.identitas) return
      this.dialogComponent = true
      this.curComponent = 'FormAddFarmPeternak'
      this.sdataForm = {...this.identitas, 'peternak': this.form.peternak}
    }, 
    closeDialogForm(res) {
      if (res.status) {
        if (res.refresh && this.curComponent == 'FormAddPeternak') {
          this.formIdentitas = res.data
          this.onSubmitIdentitas(this.$event)
        }
        if (res.refresh && this.curComponent == 'FormAddFarmPeternak') {
          this.searchFarm = res.data.name
          this.getFarm()
        }
        this.curComponent = null
        this.sdataForm = null
        this.dialogComponent = false
      }
    },
    showAddPeternak(data) {
      if (!data) return

      if (!['nib', 'nik'].includes(data.tipe_kode)) return

      this.modalAdd = true
    },
    selectFarm(item) {
      this.form.farm = item.value
      this.farm.nama = item.text
      this.farm.provinsi = item.provinsi.uraian
      this.farm.kabupaten = item.kabupaten.uraian
      this.farm.kecamatan = item.kecamatan.uraian
      this.farm.desa = item.desa.uraian
      this.farm.alamat = item.alamat

      this.closeDialogFarm()
    },
    normalizer(node) {
      if (!node) return
      return {
        id: node.value,
        label: `${node.text} ${this.$options.filters.dateIndonesia(node.tanggal)}`,
        children: node.childrens,
      }
    },
    getTernakStrain() {
      MasterService.getTernakStrain()
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsStrain = [{
                  value: null,
                  text: "Tidak ada Strain"
              }]
              return;
          }

          this.opsStrain = res.data.data
          this.opsStrain.push({
              value: null,
              text: "Silahkan pilih Strain"
          })
      })
      .catch((err) => {
        ErrorService.status(err)
        this.opsStrain = [{
            value: null,
            text: "Tidak ada Strain"
        }]
      })
    },  
    getFarm() {
      this.isBusy = true
      PeternakService.getPeternakFarm({ child: 1, peternak: this.form.peternak })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsFarm = []
                this.isBusy = false
                return;
            }

            this.opsFarm = res.data.data
            this.isBusy = false
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsFarm = []
          this.isBusy = false
        })
    },
    getTernakSex() {
      MasterService.getTernakSex({tipe: 6})
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsSex = [{
                  value: null,
                  text: "Tidak ada Sex"
              }]
              return;
          }

          this.opsSex = res.data.data
          this.opsSex.push({
              value: null,
              text: "Silahkan pilih Sex"
          })
      })
      .catch((err) => {
        ErrorService.status(err)
        this.opsSex = [{
            value: null,
            text: "Tidak ada Sex"
        }]
      })
    },
    getKategoriDistribusi() {
      MasterService.getKategoriDistribusi()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKategoriDistribusi = [{
                  value: null,
                  text: "Tidak ada Int/Eks"
                }]
                return;
            }

            this.opsKategoriDistribusi = res.data.data
            this.opsKategoriDistribusi.push({
              value: null,
              text: "Silahkan pilih Int/Eks"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsKategoriDistribusi = [{
            value: null,
            text: "Tidak ada Int/Eks"
          }]
        })
    },
    getProduksiDOC(tanggal) {
      if (!tanggal) {
        return
      }
      DistribusiService.getProduksiDOC({tanggal})
      .then((res) => {
        if (!res.data.status) {
            ErrorService.message(res.data)
            this.opsAsalDOC = []
            this.loading = false
            return;
        }
        this.opsAsalDOC = res.data.data
        this.loading = false
      })
      .catch((err) => {
        this.opsAsalDOC = []
        this.loading = false
        return ErrorService.status(err)
      })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmitIdentitas(evt) {
      evt.preventDefault()
      this.$refs.formIdentitas.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit_identitas"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            PeternakService.findPeternak(this.formIdentitas)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    if (!res.data.status) {
                      this.showAddPeternak(this.formIdentitas)
                      ErrorService.message(res.data)
                      return
                    }
                    this.identitas = {
                      nama: res.data.data.nama,
                      kode: res.data.data.kode,
                      tipe: res.data.data.kategoriusaha.identitas
                    }
                    this.form.peternak = res.data.data._id
                    this.form.kode = res.data.data.kode
                })
                .catch((err) => {
                  this.showAddPeternak(this.formIdentitas)
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if(!this.form.farm) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Rincian data tidak lengkap',
            })
            return;
        }

        if (this.cekSex.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Double input sex',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            DistribusiService.addSendDoc(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.$router.push({ name: 'unggas-nasional-distribusi-tabel-list', param: {idH: this.sdata._id} })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form.peternak = null
      this.form.kode = null
      this.form.farm = null
      this.form.kategori = null
      this.form.tipe = 6
      this.form.strain = null
      this.form.asal = []
      this.form.rincian = [{
        sex: null,
        jumlah: 0
      }]

      this.farm = {
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
      }

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Peternak" }]);
    this.getKategoriDistribusi()
    this.getTernakStrain()
    this.getTernakSex()
  },
};
</script>